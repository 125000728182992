:root {
    --brand-primary            : #ed1c24;
    --brand-primary-lighten-10 : #f14b52;
    --brand-primary-lighten-40 : #fcd9da;
    --brand-primary-darken-5   : #de1219;
    --brand-primary-darken-10  : #c61017;
    --brand-primary-darken-12  : #bd0f16;
    --brand-primary-opacity-25 : #ed1c2440;
    --brand-primary-opacity-50 : #ed1c2480;

    --brand-secondary          : var(--brand-primary);

    --highlight                : #fbcbcd;
    --highlight-tint           : #fef1f1;

    --navbar-height            : 60px;
    --navbar-brand-width       : 419px;
    --navbar-brand-height      : 60px;
    --navbar-brand-margin-left : 10px;
    --navbar-brand-logo-size   : auto;
    --navbar-brand-logo        : url('/assets/images/clients/toyota/TDDS_Insight_Logo.png');
    --navbar-brand-logo-mobile : url('/assets/images/clients/toyota/TDDS_Insight_Logo_Mobile.png');

    --logo-gap                 : 24px;
    --logo-bg-size             : auto;
    --login-logo-height        : auto;
    --login-logo-width         : 200px;
    --login-logo               : url('/assets/images/PoweredByShift.png');
    --login-brand-logo         : url('/assets/images/clients/toyota/login_logo.png');
    --login-brand-logo-width   : 278px;
    --login-brand-logo-height  : 35px;
    
    --sidebar-width            : 220px;
    --mobile-sidebar-width     : 220px;

    --sidebar-bg               : #000;
    --sidebar-bg-darken-3      : black;
    --sidebar-bg-darken-5      : black;
    --sidebar-bg-darken-10     : black;
    --sidebar-bg-lighten-5     : #0d0d0d;

    --combined-bar-green       : #18b52d;
    --combined-bar-blue        : #224197;
    --combined-bar-yellow      : #f9bb11;
    --combined-bar-red         : #c51230;
}